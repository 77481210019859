<template>
    <div class="block-text-slider position-absolute">
        <div class="slider">
            <div v-for="(slide, index) in slides" :key="index" class="slide">
                <div class="font-weight-bold mb-1">{{ slide.title }}</div>
                <div v-html="slide.text"></div>
                <a
                    v-if="slide.link"
                    :href="slide.link.url"
                    :target="slide.link.target"
                    >{{ slide.link.name }}<i class="fas fa-chevron-right"></i
                ></a>
            </div>
        </div>
    </div>
</template>
<script>
import { tns } from 'tiny-slider/src/tiny-slider';

export default {
    props: {
        slides: {
            type: Array,
            default() {
                return [];
            }
        }
    },

    mounted() {
        this.$nextTick(() => {
            this.initSlider();
        });
    },

    methods: {
        initSlider() {
            this.slider = tns({
                container: this.$el.querySelector('.slider'),
                controls: false,
                items: 1,
                gutter: 20,
                loop: false,
                slideBy: 1,
                mouseDrag: true,
                nav: true,
                navPosition: 'bottom',
                preventScrollOnTouch: 'auto',
                speed: 400
            });
        }
    }
};
</script>
<style lang="scss">
.block-text-slider {
    width: 100%;
    height: auto;
    color: $color-white;
    background-color: $color-blue;
    padding: 30px;
    z-index: 2;
    left: 0;
    bottom: -50px;

    @include media-breakpoint-up(md) {
        min-height: 510px;
        width: 585px;
        padding: 50px 80px;
    }
}

.block-text-slider .slider {
    display: flex;
}

.block-text-slider .tns-ovh {
    overflow: hidden;
}

.block-text-slider .tns-nav {
    text-align: center;

    button {
        margin-right: 20px;
        height: 16px;
        background-color: transparent;
        border: 2px solid $color-white;

        &.tns-nav-active {
            background-color: $color-white;
        }

        &:hover {
            background-color: $color-white;
        }
    }
}

.block-text-slider .tns-liveregion {
    display: none;
}

.slide {
    margin-bottom: 30px;
    a {
        font-weight: $font-weight-bold;
        color: $color-white;
        &:hover {
            color: $color-white;
        }
    }
    i {
        padding-left: 20px;
    }
}
</style>
